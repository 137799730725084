// src/pages/RSVP.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const venueDetails = {
  Philadelphia: {
    location: 'Assembly Rooftop Lounge',
    datetime: 'Monday, April 7 · 7:00–10:00pm',
    address: 'https://maps.app.goo.gl/PmpHp871yJipjh5i9',
  },
  Harrisburg: {
    location: 'The Millworks',
    datetime: 'Wednesday, April 9 · 7:00–10:00pm',
    address: 'https://maps.app.goo.gl/DVmchtsdGxnDy5kSA',
  },
  Pittsburgh: {
    location: 'Hofbräuhaus Pittsburgh',
    datetime: 'Friday, April 11 · 7:00–10:00pm',
    address: 'https://maps.app.goo.gl/PZJcT4rUh3kbtRhm8',
  },
  Scranton: {
    location: 'Backyard Ale House',
    datetime: 'Monday, April 14 · 7:00–10:00pm',
    address: 'https://maps.app.goo.gl/6NaagNnxRwo3zvsq9',
  },
};

const RSVP = () => {
 const [formData, setFormData] = useState({
    name: '',
    email: '',
    city: '',
    message: '',
    venue: '',
    datetime: '',
    maplink: '',
  });

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleChange = (e) => {
    const updatedForm = { ...formData, [e.target.name]: e.target.value };

    if (e.target.name === 'city') {
      const venue = venueDetails[e.target.value];
      updatedForm.venue = venue?.location || '';
      updatedForm.datetime = venue?.datetime || '';
      updatedForm.maplink = venue?.address || '';
    }

    setFormData(updatedForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus({ submitted: false, submitting: true, info: { error: false, msg: null } });

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_RSVP;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        setStatus({
          submitted: true,
          submitting: false,
          info: { error: false, msg: '🎉 You’re in! A confirmation has been sent to your email. See you soon.' },
        });
        setFormData({
          name: '',
          email: '',
          city: '',
          message: '',
          venue: '',
          datetime: '',
          maplink: '',
        });
      })
      .catch((err) => {
        setStatus({
          submitted: true,
          submitting: false,
          info: { error: true, msg: 'Oops! Something went wrong. Please try again or email us directly.' },
        });
      });
  };

  return (
    <Container className="my-5">
      <div className="text-center mb-5">
        <h1 className="mb-3" data-aos="fade-up">✨ RxRooster CRNA Mixer – Your Exclusive Invite</h1>
        <p className="lead" data-aos="fade-up" data-aos-delay="100">
          You’ve worked hard. You deserve this. Join an intimate gathering designed <strong>just for CRNAs</strong> – no vendors, no fluff, just real connections, strong cocktails, and good vibes.
        </p>
      </div>

      <Row className="text-center mb-5">
        {Object.entries(venueDetails).map(([city, details], index) => {
            const emoji = {
            Philadelphia: '🌆',
            Harrisburg: '🎨',
            Pittsburgh: '🏙️',
            Scranton: '🌳',
            }[city];

            return (
            <Col md={3} key={city} data-aos="fade-up" data-aos-delay={index * 100}>
                <h5>{emoji} {city}</h5>
                <p>
                <strong>
                    <a href={details.address} target="_blank" rel="noopener noreferrer">
                    {details.location}
                    </a>
                </strong><br />
                {city === 'Philadelphia' && 'Skyline views & swanky sips'}
                {city === 'Harrisburg' && 'Art, local food, and open-air vibes'}
                {city === 'Pittsburgh' && 'Easygoing beer garden energy with Bavarian flair'}
                {city === 'Scranton' && 'Chill backyard hangs'}
                <br />
                🗓️ {details.datetime}
                </p>
            </Col>
            );
        })}
        </Row>

      <div className="mb-4 text-center" data-aos="fade-up">
        <p className="mb-0">🚨 <strong>Join us for a special evening with a close-knit group of fellow CRNAs.</strong> <br />These mixers are intentionally small to create a relaxed, personal vibe.</p>
      </div>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" data-aos="fade-up">
          <Form.Label>Your Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail" data-aos="fade-up" data-aos-delay="100">
          <Form.Label>Your Email*</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formCity" data-aos="fade-up" data-aos-delay="200">
          <Form.Label>Select Your City*</Form.Label>
          <Form.Control
            as="select"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          >
            <option value="">-- Choose one --</option>
            <option value="Philadelphia">Philadelphia – Assembly Rooftop Lounge</option>
            <option value="Harrisburg">Harrisburg – The Millworks</option>
            <option value="Pittsburgh">Pittsburgh – Hofbräuhaus Pittsburgh</option>
            <option value="Scranton">Scranton – Backyard Ale House</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formMessage" data-aos="fade-up" data-aos-delay="300">
          <Form.Label>Leave a Note (Optional)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Any allergies, fun facts, or questions?"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>

        <div className="text-center">
          <Button variant="primary" type="submit" disabled={status.submitting} className="mt-4 px-5 py-2" data-aos="fade-up" data-aos-delay="400">
            {status.submitting ? 'Sending...' : '🎟 Reserve My Spot'}
          </Button>
        </div>
      </Form>

      {status.submitted && status.info.msg && (
        <Alert
          variant={status.info.error ? 'danger' : 'success'}
          className="mt-4"
          onClose={() => setStatus({ ...status, info: { error: false, msg: null } })}
          dismissible
          data-aos="fade-up" data-aos-delay="500"
        >
          {status.info.msg}
        </Alert>
      )}
    </Container>
  );
};

export default RSVP;
