import React, { useState, useEffect } from 'react';
import {
  Container,
  Form,
  Button,
  Alert,
  Row,
  Col,
  Card,
  ProgressBar,
} from 'react-bootstrap';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ConsultationForm.css'; 
import CalendlyEmbed from './CalendlyEmbed';

const ConsultationForm = () => {
  const [formData, setFormData] = useState({
    preference: [],
    specialization: [],
    hasLocumsExperience: '',
    hourlyRate: '',
    name: '',
    email: '',
    phone: '',
  });

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const [currentStep, setCurrentStep] = useState(1);

  const totalSteps = 8;

  const preferencesOptions = [
    { value: 'Flexibility', label: 'Improved Flexibility' },
    { value: 'Higher Wages', label: 'Higher Wages' },
    { value: 'Work-Life Balance', label: 'Better Work-Life Balance' },
    { value: 'Career Advancement', label: 'Career Advancement Opportunities' },
    { value: 'Specific Location Preferences', label: 'Specific Location Preferences' },
    { value: 'Augment Wages', label: 'Augment Wages' },
  ];

  const specializationOptions = [
    { value: 'Pediatric Anesthesia', label: 'Pediatric Anesthesia' },
    { value: 'Cardiac Anesthesia', label: 'Cardiac Anesthesia' },
    { value: 'Neuroanesthesia', label: 'Neuroanesthesia' },
    { value: 'Obstetric Anesthesia', label: 'Obstetric Anesthesia' },
    { value: 'Regional Anesthesia', label: 'Regional Anesthesia' },
  ];

  const hourlyRateRanges = [
    { value: '180-210', label: '$180 - $210' },
    { value: '210-240', label: '$210 - $240' },
    { value: '240-270', label: '$240 - $270' },
    { value: '270-310', label: '$270 - $310' },
    { value: '310+', label: '$310+' },
  ];

  // ---------------------------
  //   Handlers & Validation
  // ---------------------------
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        [name]: checked
          ? [...prev[name], value]
          : prev[name].filter((item) => item !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const validateStep = () => {
    switch (currentStep) {
      case 1:
        return formData.preference.length > 0;
      case 2:
        return formData.hasLocumsExperience !== '';
      case 3:
        return formData.hourlyRate !== '';
      case 4:
        return formData.name.trim() !== '';
      case 5:
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
      case 6:
        // phone is optional
        return true;
      case 7:
        // Calendly step has no direct form validation
        return true;
      case 8:
        // Calendly step has no direct form validation
        return true;
      default:
        return false;
    }
  };

  // -----------------------------------------
  //  Scheduling & Submitting the Form
  // -----------------------------------------
  // This is called automatically when the user finishes scheduling in Calendly (step 7).
  const handleSubmit = () => {
    setStatus({
      submitted: false,
      submitting: true,
      info: { error: false, msg: null },
    });

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONSULTATION;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    // Basic check for environment variables
    if (!serviceID || !templateID || !userID) {
      console.error('EmailJS environment variables are missing.');
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: true, msg: 'Internal configuration error. Please try again later.' },
      });
      return;
    }

    const templateParams = {
      preference: formData.preference.join(', '),
      specialization: formData.specialization.join(', '),
      hasLocumsExperience: formData.hasLocumsExperience,
      hourlyRate: formData.hourlyRate,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then(() => {
        setStatus({
          submitted: true,
          submitting: false,
          info: {
            error: false,
            msg: 'Thank you for scheduling a consultation! We will get back to you shortly.',
          },
        });
        // Reset the form
        setFormData({
          preference: [],
          specialization: [],
          hasLocumsExperience: '',
          hourlyRate: '',
          name: '',
          email: '',
          phone: '',
        });
        // Move to the celebration step
        setCurrentStep(9);
      })
      .catch((err) => {
        console.error(err);
        setStatus({
          submitted: true,
          submitting: false,
          info: {
            error: true,
            msg: 'Failed to send your request. Please try again later.',
          },
        });
      });
  };

  // -----------------------------------------
  //  Calendly Integration
  // -----------------------------------------
  useEffect(() => {
    const handleCalendlyEvent = (event) => {
      // If Calendly scheduling is completed...
      if (event.data.event && event.data.event.indexOf('calendly.') === 0) {
        if (event.data.event === 'calendly.event_scheduled') {
          // Submit the form automatically
          handleSubmit();
        }
      }
    };

    if (currentStep === 8) {
      window.addEventListener('message', handleCalendlyEvent);
    }
    return () => {
      if (currentStep === 8) {
        window.removeEventListener('message', handleCalendlyEvent);
      }
    };
  }, [currentStep]);

  // Load Calendly script once
  useEffect(() => {
    const scriptId = 'calendly-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  // -----------------------------------------
  //  Render Steps
  // -----------------------------------------
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Form.Group controlId="formPreference" className="mb-3">
            <Form.Label>
              What are you looking for in a locums gig? <span className="text-danger">* (select all that apply)</span>
            </Form.Label>
            {preferencesOptions.map((option, index) => {
              const uniqueId = `preference_${option.value}_${index}`;
              return (
                <Form.Check
                  key={uniqueId}
                  id={uniqueId}
                  label={option.label}
                  name="preference"
                  type="checkbox"
                  value={option.value}
                  checked={formData.preference.includes(option.value)}
                  onChange={handleChange}
                  className="mb-2"
                />
              );
            })}
          </Form.Group>
        );
      case 2:
        return (
          <Form.Group controlId="formLocumsExperience" className="mb-3">
            <Form.Label>
              Have you done locums before? <span className="text-danger">*</span>
            </Form.Label>
            <div>
              <Form.Check
                label="Yes"
                name="hasLocumsExperience"
                type="radio"
                id="hasLocumsExperience_yes"
                value="Yes"
                checked={formData.hasLocumsExperience === 'Yes'}
                onChange={handleChange}
                required
                className="mb-2"
              />
              <Form.Check
                label="No"
                name="hasLocumsExperience"
                type="radio"
                id="hasLocumsExperience_no"
                value="No"
                checked={formData.hasLocumsExperience === 'No'}
                onChange={handleChange}
                required
                className="mb-2"
              />
            </div>
          </Form.Group>
        );
      case 3:
        return (
          <Form.Group controlId="formHourlyRate" className="mb-3">
            <Form.Label>
              Desired Hourly Rate <span className="text-danger">*</span>
            </Form.Label>
            {hourlyRateRanges.map((option, index) => {
              const uniqueId = `hourlyRate_${option.value}_${index}`;
              return (
                <Form.Check
                  key={uniqueId}
                  id={uniqueId}
                  label={option.label}
                  name="hourlyRate"
                  type="radio"
                  value={option.value}
                  checked={formData.hourlyRate === option.value}
                  onChange={handleChange}
                  required
                  className="mb-2"
                />
              );
            })}
          </Form.Group>
        );
      case 4:
        return (
          <Form.Group controlId="formName" className="mb-3">
            <Form.Label>
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
        );
      case 5:
        return (
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
        );
      case 6:
        return (
          <Form.Group controlId="formPhone" className="mb-3">
            <Form.Label>
              Phone Number <span className="text-muted">(Optional)</span>
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="e.g., +1234567890"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>
        );
      case 7: 
        return (
          <Form.Group controlId="formSpecialization" className="mb-3">
            <Form.Label>
              Specialization? <span className="text-danger"> (select all that apply)</span>
            </Form.Label>
            {specializationOptions.map((option, index) => {
              const uniqueId = `specialization_${option.value}_${index}`;
              return (
                <Form.Check
                  key={uniqueId}
                  id={uniqueId}
                  label={option.label}
                  name="specialization"
                  type="checkbox"
                  value={option.value}
                  checked={formData.specialization.includes(option.value)}
                  onChange={handleChange}
                  className="mb-2"
                />
              );
            })}
          </Form.Group>
        );
      case 8:
        // Calendly embed step
        // No "Next" button here; scheduling triggers form submission automatically
        return (
          <CalendlyEmbed
            name={formData.name}
            email={formData.email}
          />
        );
      case 9:
        // Celebratory animation step
        return (
          <div className="text-center my-5">
            <img
              src="https://rxrooster.com/logo-rooster.png"
              alt="RxRooster Logo"
              style={{ width: '150px', marginBottom: '1rem' }}
            />
            <h2 className="mb-3">Congratulations on Scheduling Your Consultation!</h2>
            <p className="lead">
              Thank you for scheduling a consultation. An email with your details has been sent
              to you. Feel free to check your inbox for more information or to reschedule if needed.
            </p>
            {/* Simple CSS keyframe-based animation or a celebratory effect could be placed here */}
            
          </div>
        );
      default:
        return null;
    }
  };

  // -----------------------------------------
  //  Rendering the main component
  // -----------------------------------------
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <Card className="shadow-lg border-0">
            <Card.Body>
              {/* Progress Bar */}
              {currentStep < 8 && (
                <ProgressBar
                  now={(currentStep / totalSteps) * 100}
                  label={`Step ${currentStep} of ${totalSteps}`}
                  className="mb-4"
                  animated
                  striped
                />
              )}

              {currentStep < 8 && (
                <>
                  <h2 className="text-center mb-3">Schedule a Consultation</h2>
                  <p className="text-center text-muted mb-4">
                    Let us help you find the perfect locums gig tailored to your needs.
                  </p>
                </>
              )}

              <Form onSubmit={(e) => e.preventDefault()} /* No direct onSubmit needed now */>
                {renderStep()}

                {/* Navigation Buttons (shown only up to step 6) */}
                {currentStep < 8 && (
                  <div className="d-flex justify-content-between mt-4">
                    {currentStep > 1 && (
                      <Button variant="secondary" onClick={handleBack}>
                        Back
                      </Button>
                    )}
                    {currentStep < 8 && (
                      <Button variant="primary" onClick={handleNext}>
                        Next
                      </Button>
                    )}
                  </div>
                )}
              </Form>

              {/* Alert Message */}
              {status.submitted && status.info.msg && (
                <Alert
                  variant={status.info.error ? 'danger' : 'success'}
                  className="mt-4"
                  onClose={() =>
                    setStatus({ ...status, info: { error: false, msg: null } })
                  }
                  dismissible
                >
                  {status.info.msg}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ConsultationForm;
