import React, { useEffect } from 'react';

function CalendlyEmbed({ name, email }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Pre-fill Calendly with name and email
  const calendlyUrl = `https://calendly.com/alma-rxrooster?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}`;

  return (
    <div
      className="calendly-inline-widget"
      data-url={calendlyUrl}
      style={{ minWidth: '320px', height: '700px' }}
    />
  );
}

export default CalendlyEmbed;
