// src/components/ContactForm.js
import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus({ submitted: false, submitting: true, info: { error: false, msg: null } });

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID =  process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONTACT_US;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus({
          submitted: true,
          submitting: false,
          info: { error: false, msg: 'Thank you for contacting us! We will get back to you shortly.' },
        });
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((err) => {
        console.error('FAILED...', err);
        setStatus({
          submitted: true,
          submitting: false,
          info: { error: true, msg: 'Failed to send message. Please try again later.' },
        });
      });
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4" data-aos="fade-up">Contact Us</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" data-aos="fade-up" data-aos-delay="100">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail" data-aos="fade-up" data-aos-delay="200">
          <Form.Label>Email*</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMessage" data-aos="fade-up" data-aos-delay="300">
          <Form.Label>Message*</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Your message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={status.submitting} className="mt-3" data-aos="fade-up" data-aos-delay="400">
          {status.submitting ? 'Sending...' : 'Submit'}
        </Button>
      </Form>

      {status.submitted && status.info.msg && (
        <Alert
          variant={status.info.error ? 'danger' : 'success'}
          className="mt-4"
          onClose={() => setStatus({ ...status, info: { error: false, msg: null } })}
          dismissible
          data-aos="fade-up" data-aos-delay="500"
        >
          {status.info.msg}
        </Alert>
      )}
    </Container>
  );
};

export default ContactForm;
